import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-loader',
  template: `
    <div *ngIf="isLoading">Søker...</div>
  `
})
export class LoaderComponent {
  @Input() isLoading: boolean;
}
