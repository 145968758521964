import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {map} from 'rxjs/operators';
import {VehicleService} from '../../core/vehicle.service';
import {Observable, Subscription} from 'rxjs';
import {AppService} from '../../core/app.service';

@Component({
  selector: 'app-regno-search',
  templateUrl: './reg-no-search-box.component.html'
})
export class RegNoSearchBoxComponent implements OnInit, OnDestroy {
  @Output() setVehicle: EventEmitter<any> = new EventEmitter<any>();

  regno: string;
  isLoading$: Observable<boolean>;
  error: string;
  vehicle: any;
  history: any[];

  private subscription: Subscription;

  constructor(private vehicleService: VehicleService, private app: AppService) {}

  ngOnInit(): void {
    this.isLoading$ = this.app.loading$;
  }

  searchRegno(): void {
    this.error = null;

    this.subscription = this.vehicleService.vehicle(this.regno)
      .pipe(
        map((vehicle) => {
          if (vehicle && vehicle.kjennemerke) {
            this.vehicle = vehicle;
            this.setVehicle.emit(vehicle);
          } else {
            this.error = `Vi fant dessverre ingen kjøretøy med kjennemerke "${this.regno}".`;
          }
        })
      )
      .subscribe();

    // this.subscription = this.vehicleService.vehicle(this.regno)
    //   .pipe(
    //     map((vehicle) => {
    //       if (vehicle.kjennemerke) {
    //         this.history = [
    //           {date: '2018-11-02', order_no: '1455642', invoice_no: '12344674', text: 'Skiftet regreim.', workshop: 'CAS'},
    //           {date: '2018-11-02', order_no: '1455642', invoice_no: '12344674', text: 'Skiftet regreim.', workshop: 'CAS'},
    //           {date: '2018-11-02', order_no: '1455642', invoice_no: '12344674', text: 'Skiftet regreim.', workshop: 'CAS'},
    //           {date: '2018-11-02', order_no: '1455642', invoice_no: '12344674', text: 'Skiftet regreim.', workshop: 'CAS'},
    //           {date: '2018-11-02', order_no: '1455642', invoice_no: '12344674', text: 'Skiftet regreim.', workshop: 'CAS'},
    //           {date: '2018-11-02', order_no: '1455642', invoice_no: '12344674', text: 'Skiftet regreim.', workshop: 'CAS'},
    //           {date: '2018-11-02', order_no: '1455642', invoice_no: '12344674', text: 'Skiftet regreim.', workshop: 'CAS'},
    //           {date: '2018-11-02', order_no: '1455642', invoice_no: '12344674', text: 'Skiftet regreim.', workshop: 'CAS'},
    //           {date: '2018-11-02', order_no: '1455642', invoice_no: '12344674', text: 'Skiftet regreim.', workshop: 'CAS'},
    //           {date: '2018-11-02', order_no: '1455642', invoice_no: '12344674', text: 'Skiftet regreim.', workshop: 'CAS'},
    //           {date: '2018-11-02', order_no: '1455642', invoice_no: '12344674', text: 'Skiftet regreim.', workshop: 'CAS'},
    //           {date: '2018-11-02', order_no: '1455642', invoice_no: '12344674', text: 'Skiftet regreim.', workshop: 'CAS'},
    //           {date: '2018-11-02', order_no: '1455642', invoice_no: '12344674', text: 'Skiftet regreim.', workshop: 'CAS'},
    //           {date: '2018-11-02', order_no: '1455642', invoice_no: '12344674', text: 'Skiftet regreim.', workshop: 'CAS'},
    //         ];
    //
    //         this.vehicle = vehicle;
    //       } else {
    //         this.error = `Vi fant dessverre ingen kjøretøy med kjennemerke ${this.regno}.`;
    //       }
    //
    //       this.isLoading = false;
    //     })
    //   ).subscribe();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
