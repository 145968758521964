import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-tabs',
  template: `
    <div class="tabs">
      <a class="tab" *ngFor="let tab of tabs; let i = index" (click)="tabSelected.emit(i)"
         [ngClass]="{'tab-selected': selectedIndex === i}">
        {{ tab }}
      </a>
    </div>
  `
})
export class TabsComponent {
  @Input() tabs: string[];
  @Input() selectedIndex: number;
  @Output() tabSelected: EventEmitter<number> = new EventEmitter<number>();
}
