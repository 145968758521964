import {NgModule} from '@angular/core';
import {VehicleService} from './vehicle.service';
import {AppService} from './app.service';

@NgModule({
  providers: [
    VehicleService,
    AppService
  ]
})
export class CoreModule {}
