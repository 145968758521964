import {Component} from '@angular/core';

@Component({
  selector: 'app-info',
  template: `
    <div class="container u-margin-top-xl u-text-center u-padding-bottom-xl">
      <div class="box u-margin-center">
        <h2>Digitalt Servicehefte:</h2>
        <p class="text-center">
          Det digitale servicehefte inneholder alle opplysninger om hva som er gjort med et konkret kjøretøy.
          Her finner du servicer og alt annet som er utført på dette kjøretøyet utført hos et BilXtra verksted.
          I tillegg vil du kunne se diverse teknisk informasjon om din bil.
        </p>

        <h3 class="u-margin-top-l">Fordeler med digitalt:</h3>
        <ul class="u-text-left">
          <li>Papirhefter finnes nesten ikke i nye biler</li>
          <li>Digitalt inneholder også andre jobber i tillegg til service</li>
          <li>Ved kjøp og salg av kjøretøy</li>
          <li>Ved reklamasjon</li>
          <li>Ved dokumentasjon mot andre aktører</li>
          <li>Felles historikk ved bruk av flere Bilxtra verksted</li>
          <li>Full historikk hele døgnet og uansett hvor du er</li>
          <li>Tilgjengelig for andre Bilxtra verksted</li>
          <li>Ved kjøp, sjekk heftelser på kjøretøyet</li>
        </ul>
      </div>
    </div>
  `
})
export class InfoComponent {}
