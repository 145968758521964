import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-history',
  template: `
    <p *ngIf="history?.length === 0">Vi fant ingen historikk på denne bilen.</p>
    <div *ngFor="let h of history; let i = index" class=" u-margin-bottom-l">
      <h2 class="u-text-left">Fakturadato: {{ (h.invoice_date | date:'dd.MM.yyyy') || 'Ingen dato' }}</h2>
      <div class="flex-row u-text-left u-padding-m u-border-bottom">
        <div class="flex-1">Faktura nr.</div>
        <div class="flex-2">{{ h.invoice_no }}</div>
      </div>
      <div class="flex-row u-text-left u-padding-m u-border-bottom">
        <div class="flex-1">Ordrenr.</div>
        <div class="flex-2">{{ h.order_no }}</div>
      </div>
      <div class="flex-row u-text-left u-padding-m u-border-bottom">
        <div class="flex-1">Kilometerstand</div>
        <div class="flex-2">{{ h.mileage }}</div>
      </div>
      <div class="flex-row u-text-left u-padding-m u-border-bottom">
        <div class="flex-1">Ordrelinjer</div>
        <div class="flex-2" *ngIf="showOrderLines.indexOf(i) === -1">
          <button *ngIf="h.text && h.text.length > 0" type="button" class="button"
                  (click)="toggleShowOrderLines(i)">
            Vis ordrelinjer
          </button>
          <p *ngIf="h.text && h.text.length === 0" class="u-margin-bottom-s">Ingen orderlinjer.</p>
        </div>
        <div class="flex-2" *ngIf="showOrderLines.indexOf(i) !== -1">
          <p class="u-margin-bottom-s" *ngFor="let line of h.text">
            {{ line }}
          </p>
          <button type="button" class="button" (click)="toggleShowOrderLines(i)">
            Skjul ordrelinjer
          </button>
        </div>
      </div>
      <div class="flex-row u-text-left u-padding-m u-border-bottom">
        <div class="flex-1">Verksted</div>
        <div class="flex-2">{{ h.workshop_name }}</div>
      </div>
    </div>
  `
})
export class HistoryComponent {
  @Input() history: any[];

  showOrderLines: number[] = [];

  toggleShowOrderLines(historyIndex: number): void {
    const index = this.showOrderLines.indexOf(historyIndex);
    const isShowing = index !== -1;

    if (isShowing) {
      this.showOrderLines.splice(index, 1);
    } else {
      this.showOrderLines = (this.showOrderLines) ? [].concat(...this.showOrderLines, historyIndex) : [].concat(historyIndex);
    }
  }
}
