import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {AppService} from './app.service';
import {environment} from '../../environments/environment';

@Injectable()
export class VehicleService {

  constructor(private http: HttpClient, private app: AppService) {}

  vehicle(regno: string): Observable<any> {
    this.app.loading$.next(true);
    const url = `${environment.API_URL}vehicle/${regno}`;

    return this.http
      .get(url)
      .pipe(
        map((results) => {
          this.app.loading$.next(false);
          return results;
        }),
        catchError(error => {
          this.app.loading$.next(false);
          return error;
        })
      );
  }

  getVehicleHistory(regno: string): Observable<any> {
    const url = `${environment.API_URL}history/${regno}`;
    return this.http.get(url).pipe(map((response: any) => response.history));
  }

}
