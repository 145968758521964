import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-vehicle-info',
  template: `
    <div class="flex flex-row" *ngFor="let i of keys">
      <div class="flex-1 u-text-left">
        {{ i.key }}
      </div>
      <div class="flex-2 u-text-left">
            <span *ngIf="!i.convert">
              {{ vehicle[i.value] }}
            </span>
        <span *ngIf="i.convert">
              {{ i['convert'][vehicle[i.value]] }}
            </span>
      </div>
    </div>
  `
})
export class VehicleInfoComponent {
  @Input() vehicle: any;
  @Input() keys: any;
}
