import {NgModule} from '@angular/core';
import {RegNoSearchBoxComponent} from './reg-no-search-box/reg-no-search-box.component';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {HeaderComponent} from './header/header.component';
import {InfoComponent} from './info.component';
import {LoaderComponent} from './loader.component';
import {VehicleInfoComponent} from './vehicle-info.component';
import {TabsComponent} from './tabs.component';
import {HistoryComponent} from './history.component';

@NgModule({
  declarations: [
    RegNoSearchBoxComponent,
    HeaderComponent,
    InfoComponent,
    LoaderComponent,
    VehicleInfoComponent,
    TabsComponent,
    HistoryComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    RegNoSearchBoxComponent,
    HeaderComponent,
    InfoComponent,
    LoaderComponent,
    VehicleInfoComponent,
    TabsComponent,
    HistoryComponent
  ]
})
export class SharedModule {

}
