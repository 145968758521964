import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {CoreModule} from './core/core.module';
import {HttpClientModule} from '@angular/common/http';
import {SharedModule} from './shared/shared.module';

import {registerLocaleData} from '@angular/common';
import nb from '@angular/common/locales/nb';
registerLocaleData(nb);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    SharedModule
  ],
  providers: [{provide: LOCALE_ID, useValue: 'nb'}],
  bootstrap: [AppComponent]
})
export class AppModule { }
